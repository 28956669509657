const header = document.getElementById('header');
const nav = document.getElementById('nav');
const navToggle = document.getElementById('nav-toggle');

const showNav = () => {
    nav.classList.remove('collapsed');
    navToggle.classList.remove('collapsed');
    nav.setAttribute('aria-hidden','false');
};

const hideNav = () => {
    nav.classList.add('collapsed');
    navToggle.classList.add('collapsed');
    nav.setAttribute('aria-hidden','true');
};

const toggleNav = () => {
    if (nav.classList.contains('collapsed')) {
        showNav();
    } else {
        hideNav();
    }
};

navToggle.addEventListener('click', e => {
    e.preventDefault();
    toggleNav();
});

// close nav when esc key is pressed
document.addEventListener('keydown', (e = global.event) => {
	if (!nav.classList.contains('collapsed')) {
		let isEscape = false;

		if ('key' in e) {
			isEscape = e.key === 'Escape' || e.key === 'Esc';
		} else {
			isEscape = e.keyCode === 27;
		}

		if (isEscape) {
			hideNav();
		}
	}
});

// close nav when clicking outside header
document.addEventListener('click', e => {
    if (!nav.classList.contains('collapsed') && !header.contains(e.target)) {
        hideNav();
    }
});