import { tns } from 'tiny-slider';

const wbcf = document.getElementById('where-butter-comes-from');

const loadSlider = () => {
    const imageSlider = tns({
        container: '#wbcf-image-slider',
        mode: 'gallery',
        navContainer: '#wbcf-slider-nav',
        controlsContainer: '#wbcf-slider-controls',
        lazyload: true,
        autoplay: true,
        autoplayTimeout: 8000,
        swipeAngle: 45
    });

    const textSlider = tns({
        container: '#wbcf-text-slider',
        mode: 'gallery',
        nav: false,
        controls: false,
        loop: false,
        touch: false
    });

    imageSlider.events.on('indexChanged', () => {
        const info = imageSlider.getInfo();
        const { index, indexCached, slideCount } = info;

        if (global.event) {
            const { target } = global.event;
            const slide = info.slideItems[indexCached];
            if (target.dataset.controls && target.dataset.controls === 'prev') {
                slide.classList.add('tns-fadeToRight');
                global.setTimeout(() => {
                    slide.classList.remove('tns-fadeToRight');
                }, 500);
            }
        }

        textSlider.goTo(index > slideCount - 1 ? 0 : index);
    });
};

if (wbcf) {
    const observer = new IntersectionObserver(entries => {
        const { target, isIntersecting } = entries[0];
        if (isIntersecting) {
            loadSlider();
            observer.unobserve(target);
        }
    });
    observer.observe(wbcf);
}
