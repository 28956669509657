import emailValidator from 'email-validator';

const form = document.getElementById('review-form');
const confirmation = document.getElementById('review-confirmation');
const params = new URLSearchParams();

if (form) {
    const token = document.getElementsByName('__RequestVerificationToken')[0];
    const id = document.getElementById('DocumentGuid');
    const name = document.getElementById('FirstName');
    const email = document.getElementById('Email');
    const comment = document.getElementById('Comment');
    const rating = document.getElementById('Rating');
    const stars = Array.from(document.querySelectorAll('.custom-control-star-rating button'));        
      
    let nameIsValid = false;
    let emailIsValid = false;
    let commentIsValid = false;
    let ratingIsValid = false;
    let isValid = false;

    const resetForm = () => {
        name.value = '';
        name.parentNode.classList.remove('has-value');
        email.value = '';
        email.parentNode.classList.remove('has-value');
        comment.value = '';
        comment.parentNode.classList.remove('has-value');
        rating.parentNode.classList.remove('has-error');
        rating.value = 0;
        stars.forEach(star => {
            star.classList.remove('active');
        });
    };

    stars.forEach((star, index) => {
        star.addEventListener('click', e => {
            e.preventDefault();

            stars.forEach(star => {
                star.classList.remove('active');
            });

            for (let i = 0; i <= index; i++) {
                stars[i].classList.add('active');
            }

            rating.value = star.getAttribute('aria-label');
            rating.parentNode.classList.remove('has-error');
        });

        star.addEventListener('mouseover', () => {
            for (let i = 0; i <= index; i++) {
                stars[i].classList.add('hover');
            }
        });

        star.addEventListener('mouseout', () => {
            for (let i = 0; i <= index; i++) {
                stars[i].classList.remove('hover');
            }
        });
    });

    form.addEventListener('submit', e => {
        e.preventDefault();

        nameIsValid = name.value.trim() !== '';
        emailIsValid = emailValidator.validate(email.value);
        commentIsValid = comment.value.trim() !== '';
        ratingIsValid = rating.value != 0;
        isValid = nameIsValid && emailIsValid && commentIsValid && ratingIsValid;

        if (!nameIsValid) {
            name.parentNode.classList.add('has-error');
        }

        if (!emailIsValid) {
            email.parentNode.classList.add('has-error');
        }

        if (!commentIsValid) {
            comment.parentNode.classList.add('has-error');
        }

        if (!ratingIsValid) {
            rating.parentNode.classList.add('has-error');
        }

        if (isValid) {
            const grecaptchaResponse = document.getElementById('g-recaptcha-response');

            params.set('__RequestVerificationToken', token.value);
            params.set('DocumentGuid', id.value)
            params.set('FirstName', name.value);
            params.set('Email', email.value);
            params.set('Comment', comment.value);
            params.set('Rating', rating.value);
            params.set('GRecaptchaResponse', grecaptchaResponse.value);            

            fetch('/review/create', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: params
            })
                .then(res => res.json())
                .then(res => {
                    resetForm();
                    confirmation.classList.remove('d-none');
                    global.scrollTo(0, 0);
                });
        }
    });
}
