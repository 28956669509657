export default (selector, duration = 400, complete = () => {}) => {
	const element = document.querySelector(selector);
	const { classList, style, firstElementChild } = element;
	style.transitionDuration = `${duration}ms`;
	classList.remove('collapse');
	classList.add('collapsing');
	const height = firstElementChild ? firstElementChild.offsetHeight : 0;
	global.setTimeout(() => {
		classList.add('show');
		style.height = `${height}px`;
		global.setTimeout(() => {
			classList.remove('collapsing');
			classList.add('collapse');
			style.height = '';
			style.transitionDuration = '';
			complete();
		}, duration);
	}, 20);
};