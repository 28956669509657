﻿const { pathname } = global.location;
const recipesGrid = document.getElementById('recipes-grid');
const recipesLoader = document.getElementById('recipes-loader');

if (recipesGrid && recipesLoader && pathname.includes('collections')) {
    const pageSize = recipesGrid.dataset.pageSize || 12;

    // lazy load recipes as user scrolls down
    const loadRecipesObserver = new IntersectionObserver(entries => {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
            global.setTimeout(() => {
                const gridItems = Array.from(recipesGrid.querySelectorAll('.article-grid__item.d-none'));

                gridItems.forEach((item, index) => {
                    if (index < +pageSize) {
                        const image = item.querySelector('img');
                        image.classList.add('fade');
                        item.classList.remove('d-none');
                        global.setTimeout(() => {
                            image.classList.add('show');
                        }, 20);
                    }
                });

                if (gridItems.length < +pageSize) {
                    recipesLoader.classList.add('d-none');
                    loadRecipesObserver.disconnect();
                }
            }, 250);
        }
    }, { threshold: 1.0 });

    loadRecipesObserver.observe(recipesLoader);
}