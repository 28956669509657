const vid = document.getElementById('homeVideo');

if (vid) {
    const vidLargeSrc = vid.getAttribute('data-lg-src');
    const vidSmallSrc = vid.getAttribute('data-sm-src');
    const source = document.createElement('source');
    source.id = 'hvid';
    source.setAttribute('type', 'video/mp4');
    vid.appendChild(source);

    if (window.matchMedia('(max-width:767px)').matches) {
        vid.pause();
        source.removeAttribute('src');
        source.setAttribute('src', vidSmallSrc);
        vid.load();
        vid.play();
    } else {
        vid.pause();
        source.removeAttribute('src');
        source.setAttribute('src', vidLargeSrc);
        vid.load();
        vid.play();
    }
}