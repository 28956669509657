import modal from '../modal';

Array.from(document.querySelectorAll('[data-toggle="modal"]')).forEach(elem => {
    elem.addEventListener('click', e => {
        e.preventDefault();
        const target = elem.dataset.target || elem.getAttribute('href');
        const video = document.querySelector(target).querySelector('iframe[src*="vimeo"]');

        modal({
            target,
            beforeShow() {
                document.body.style.overflow = 'hidden';
                if (video) {
                    video.src += '?autoplay=1';
                }
            },
            beforeClose() {
                document.body.style.overflow = '';
            },
            afterClose() {
                if (video) {
                    const videoSrc = video.src.replace('?autoplay=1', '');
                    video.src = '';
                    global.setTimeout(() => {
                        video.src = videoSrc;
                    });
                }
            }
        });
    });
});

// show email sign up modal after 30 seconds, only once
if (!global.localStorage.getItem('showEmailSignUpModal')) {
    global.setTimeout(() => {
        modal({ target: '#email-sign-up-modal' });
        global.localStorage.setItem('showEmailSignUpModal', false);
    }, 30000);
}
