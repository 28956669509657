export default (selector, duration = 400, complete = () => {}) => {
	const element = document.querySelector(selector);
	const { classList, style } = element;
	style.height = `${element.offsetHeight}px`;
	style.transitionDuration = `${duration}ms`;
	classList.remove('collapse', 'show');
	classList.add('collapsing');
	global.setTimeout(() => {
		style.height = 0;
		global.setTimeout(() => {
			classList.remove('collapsing');
			classList.add('collapse');
			style.height = '';
			style.transitionDuration = '';
			complete();
		}, duration);
	}, 20);
};