import { tns } from 'tiny-slider';

const container = document.getElementById('related-content-slider');

const loadSlider = () => {
    const slider = tns({
        container,
        mode: 'gallery',
        lazyload: true,
        navPosition: 'bottom',
        speed: 500,
        autoplay: true,
        autoplayTimeout: 8000,
        swipeAngle: 45
    });

    slider.events.on('indexChanged', () => {
        const info = slider.getInfo();
        const { indexCached } = info;

        if (global.event) {
            const { target } = global.event;
            const slide = info.slideItems[indexCached];
            if (target.dataset.controls && target.dataset.controls === 'prev') {
                slide.classList.add('tns-fadeToRight');
                global.setTimeout(() => {
                    slide.classList.remove('tns-fadeToRight');
                }, 500);
            }
        }
    });
};

const onScroll = elem => {
    const top = elem.getBoundingClientRect().top;
    elem.querySelector('.related-content-slide__image .embed-responsive').style.transform = `translateY(${top * 0.1}px)`;
};

if (container) {
    // load slider only when it enters the viewport
    const sliderObserver = new IntersectionObserver(entries => {
        const { target, isIntersecting } = entries[0];
        if (isIntersecting) {
            loadSlider();
            sliderObserver.unobserve(target);
        }
    });
    sliderObserver.observe(container);

    // add parallax effect to slider images
    const parallaxObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry;
            const handleScroll = () => { onScroll(target); };

            if (isIntersecting) {
                global.addEventListener('scroll', handleScroll, true);
            } else {
                global.removeEventListener('scroll', handleScroll, true);
            }
        });
    });
    Array.from(document.querySelectorAll('.related-content-slide')).forEach(elem => {
        parallaxObserver.observe(elem);
    });
}