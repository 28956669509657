import '@webcomponents/template';
import slideUp from '../effects/slideUp';
import slideToggle from '../effects/slideToggle';

const { pathname } = global.location;
const recipesGrid = document.getElementById('recipes-grid');
const recipesSearch = document.getElementById('recipes-search');

if (recipesGrid && recipesSearch && pathname.includes('recipes')) {
    const searchQuery = document.getElementById('searchQuery');
    const filters = document.getElementById('filters');
    const filterCheckboxes = filters.querySelectorAll('input[type="checkbox"]');
    const selectedFilters = document.getElementById('selected-filters');
    const activeTags = document.getElementById('active-tags');
    const clearFilters = document.getElementById('clear-filters');
    const recipesLoader = document.getElementById('recipes-loader');
    const recipesNoResults = document.getElementById('recipes-no-results');
    const recipeGridItemTemplate = document.getElementById('article-grid-item-template');
    const btnRemoveTagTemplate = document.getElementById('btn-remove-tag-template');
    let params = new URLSearchParams(global.location.search);
    let searchTerm = params.get('searchterm') || '';
    let tags = params.get('tags') || '';
    let pageSize = +params.get('pagesize');
    let page = +params.get('page');

    pageSize = pageSize <= 0 ? 12 : pageSize;
    page = page <= 0 ? 2 : page + 1;

    const getRecipes = (options = {}) => {
        const {
            page = 1,
            pageSize = 12,
            searchTerm = '',
            tags = ''
        } = options;

        fetch(`/getrecipes?page=${page}&pagesize=${pageSize}&searchterm=${searchTerm}&tags=${tags}`)
            .then(res => res.json())
            .then(res => {
                if (page === 1 && res.length === 0) {
                    recipesNoResults.classList.remove('d-none');
                    recipesLoader.classList.add('d-none');
                    return;
                }

                res.forEach(item => {
                    const node = recipeGridItemTemplate.content.cloneNode(true);
                    const id = item.Url.replace('/recipes/', '').replace(/[{()}]/g, '');
                    const defaultImage = '/content/img/default-image.png';
                    node.querySelector('div').id = id;
                    node.querySelector('a').href = item.Url;
                    node.querySelector('img').src = item.Image !== '' ? item.Image : defaultImage;
                    node.querySelector('h2').innerHTML = item.Name;
                    recipesGrid.appendChild(node);

                    document.getElementById(id).querySelector('img').addEventListener('load', e => {
                        e.target.classList.add('loaded');
                    });
                });

                if (res.length < pageSize) {
                    recipesLoader.classList.add('d-none');
                }
            });
    };

    // lazy load recipes as user scrolls down
    const loadRecipesObserver = new IntersectionObserver(entries => {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
            global.setTimeout(() => {
                getRecipes({ page, pageSize, searchTerm, tags });
                page++;
            }, 250);
        }
    }, { threshold: 1.0 });
    loadRecipesObserver.observe(recipesLoader);

    // toggle filters
    Array.from(filters.querySelectorAll('.btn-toggle-filter-group')).forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();

            const { target } = item.dataset;

            // hide active filter group that is not this one
            const activeFilterGroup = filters.querySelector(`.filter-group.show:not(${target})`);
            if (activeFilterGroup) {
                slideUp(`.filter-group.show:not(${target})`);
                Array.from(filters.querySelectorAll(`[data-target="#${activeFilterGroup.id}"]`))
                    .forEach(item => {
                        item.classList.remove('active');
                    });
            }

            // toggle this filter group
            slideToggle(target);
            Array.from(filters.querySelectorAll(`[data-target="${target}"]`)).forEach(item => {
                item.classList.toggle('active');
            });
        });
    });

    // remove individual tag
    const removeTag = item => {
        const input = filters.querySelector(`input[value="${item.dataset.name}"]`);
        if (input) input.checked = false;
        item.remove();
    };

    // recipe search
    const Search = (historyChanged = false) => {
        searchTerm = searchQuery.value.trim();
        tags = Array.from(filterCheckboxes).filter(item => item.checked).map(item => item.value).join(',');

        // remove focus from search input
        searchQuery.blur();

        // hide active filter drawer
        const activeFilterGroup = filters.querySelector('.filter-group.show');
        if (activeFilterGroup) {
            slideUp('.filter-group.show');
            Array.from(filters.querySelectorAll(`[data-target="#${activeFilterGroup.id}"]`))
                .forEach(item => {
                    item.classList.remove('active');
                });
        }

        // hide selected filters if no tags have been selected
        if (tags.length === 0) {
            selectedFilters.classList.add('d-none');
        } else {
            selectedFilters.classList.remove('d-none');
            activeTags.innerHTML = '';
            tags.split(',').forEach(tag => {
                const node = btnRemoveTagTemplate.content.cloneNode(true);
                const btn = node.querySelector('.btn-remove-tag');
                btn.dataset.name = tag;
                btn.innerHTML += tag;
                activeTags.append(node);
            });

            Array.from(document.querySelectorAll('.btn-remove-tag')).forEach(item => {
                item.addEventListener('click', e => {
                    e.preventDefault();
                    removeTag(item);
                    new Search();
                });
            });
        }

        // reset recipes grid
        loadRecipesObserver.disconnect();
        recipesGrid.innerHTML = '';
        recipesLoader.classList.remove('d-none');
        recipesNoResults.classList.add('d-none');

        // load recipes based on search term and selected filters
        global.setTimeout(() => {
            getRecipes({ searchTerm, tags });
            page = 2;
            loadRecipesObserver.observe(recipesLoader);
        }, 250);

        // update url
        if (!historyChanged) {
            global.history.pushState({ id: 'recipes' }, '', `/recipes?searchterm=${searchTerm}&tags=${tags}`);
        }
    };

    // perform new search on form submission
    recipesSearch.addEventListener('submit', e => {
        e.preventDefault();
        new Search();
    });

    recipesSearch.addEventListener('search', () => {
        new Search();
    });

    // perform new search when clear filters button is pressed
    clearFilters.addEventListener('click', e => {
        e.preventDefault();
        Array.from(filterCheckboxes).forEach(item => {
            item.checked = false;
        });
        new Search();
    });

    // perform new search whenever a tag is removed
    Array.from(document.querySelectorAll('.btn-remove-tag')).forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();
            removeTag(item);
            new Search();
        });
    });

    // perform new search on history change
    global.addEventListener('popstate', () => {
        params = new URLSearchParams(global.location.search);
        searchQuery.value = params.get('searchterm') || '';
        tags = params.get('tags') || '';
        Array.from(filterCheckboxes).forEach(item => {
            item.checked = tags.includes(item.value);
        });
        new Search(true);
    });
}
