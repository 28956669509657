import emailValidator from 'email-validator';

const form = document.getElementById('contact-form');
const confirmation = document.getElementById('contact-confirmation');
const params = new URLSearchParams();

if (form) {
    const name = document.getElementById('contactName');
    const email = document.getElementById('contactEmail');
    const message = document.getElementById('contactMessage');
    const token = document.getElementsByName('__RequestVerificationToken')[0];
    let nameIsValid = false;
    let emailIsValid = false;
    let messageIsValid = false;
    let isValid = false;

    const resetForm = () => {
        name.value = '';
        name.parentNode.classList.remove('has-value');
        email.value = '';
        email.parentNode.classList.remove('has-value');
        message.value = '';
        message.parentNode.classList.remove('has-value');
    };

    form.addEventListener('submit', e => {
        e.preventDefault();

        nameIsValid = name.value.trim() !== '';
        emailIsValid = emailValidator.validate(email.value);
        messageIsValid = message.value.trim() !== '';
        isValid = nameIsValid && emailIsValid && messageIsValid;

        confirmation.classList.remove('show');

        if (!nameIsValid) {
            name.parentNode.classList.add('has-error');
        }

        if (!emailIsValid) {
            email.parentNode.classList.add('has-error');
        }

        if (!messageIsValid) {
            message.parentNode.classList.add('has-error');
        }

        if (isValid) {
            params.set('__RequestVerificationToken', token.value);
            params.set('contactName', name.value);
            params.set('contactEmail', email.value);
            params.set('contactMessage', message.value);

            fetch('/contact', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: params
            })
                .then(res => res.json())
                .then(res => {
                    if (res.IsSuccess) {
                        confirmation.classList.add('show');
                        resetForm();
                    }
                });
        }
    });
}
