const threshold = global.matchMedia('(min-height:768px)').matches ? [0.5, 1.0] : [0.25, 0.5, 0.75, 1.0];

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const { target, isIntersecting } = entry;
        if (isIntersecting) {
            target.classList.add('scroll-reveal--reveal');
            observer.unobserve(target);
        }
    });
}, { threshold });

Array.from(document.querySelectorAll('.scroll-reveal')).forEach(elem => {
    observer.observe(elem);
});