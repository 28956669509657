import { tns } from 'tiny-slider';

const container = document.getElementById('see-the-difference-slider');

const loadSlider = () => {
    const slider = tns({
        container,
        mode: 'gallery',
        lazyload: true,
        controlsContainer: '#see-the-difference-slider-controls',
        navContainer: '#see-the-difference-slider-nav',
        speed: 500,
        swipeAngle: 45
    });

    slider.events.on('indexChanged', () => {
        const info = slider.getInfo();
        const { indexCached } = info;

        if (global.event) {
            const { target } = global.event;
            const slide = info.slideItems[indexCached];
            if (target.dataset.controls && target.dataset.controls === 'prev') {
                slide.classList.add('tns-fadeToRight');
                global.setTimeout(() => {
                    slide.classList.remove('tns-fadeToRight');
                }, 500);
            }
        }
    });
};

if (container) {
    const observer = new IntersectionObserver(entries => {
        const { target, isIntersecting } = entries[0];
        if (isIntersecting) {
            loadSlider();
            observer.unobserve(target);
        }
    });
    observer.observe(container);
}