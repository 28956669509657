import emailValidator from 'email-validator';

const submitEmailSignUpForm = options => {
    const { formId, confirmationId, errorId } = options;
    const form = document.getElementById(formId);
    const confirmation = document.getElementById(confirmationId);
    const error = document.getElementById(errorId);
    const emailInput = form.querySelector('input[type="email"]');
    const checkboxInput = form.querySelector('input[type="checkbox"]');
    const params = new URLSearchParams();
    let emailIsValid = false;
    let checkboxIsValid = false;
    let isValid = false;

    form.addEventListener('submit', e => {
        e.preventDefault();

        emailIsValid = emailValidator.validate(emailInput.value);
        checkboxIsValid = checkboxInput.checked;
        isValid = emailIsValid && checkboxIsValid;

        error.classList.add('d-none');

        // validate email address
        if (emailIsValid) {
            emailInput.parentNode.classList.remove('has-error');
        } else {
            emailInput.parentNode.classList.add('has-error');
        }

        // validate checkbox
        if (checkboxIsValid) {
            checkboxInput.parentNode.classList.remove('has-error');
        } else {
            checkboxInput.parentNode.classList.add('has-error');
        }

        // remove error when checkbox is checked
        checkboxInput.addEventListener('focus', () => {
            checkboxInput.parentNode.classList.remove('has-error');
        });

        // submit form if it is valid
        if (isValid) {
            params.set('newsletterEmail', emailInput.value);

            fetch('/newsletter/adduser', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: params
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        form.classList.add('d-none');
                        confirmation.classList.remove('d-none');
                    } else {
                        error.classList.remove('d-none');
                    }
                });
        }
    });
};

submitEmailSignUpForm({
    formId: 'footer-email-sign-up-form',
    confirmationId: 'footer-email-sign-up-confirmation',
    errorId: 'footer-email-sign-up-error'
});