import dialogPolyfill from 'dialog-polyfill';

const modal = options => {
	const {
		target,
		backdrop = true,
		beforeShow = () => {},
		afterShow = () => {},
		beforeClose = () => {},
		afterClose = () => {}
	} = options;
	
	const modal = document.querySelector(target);
	const closeBtn = modal.querySelector('.close');
	
	const show = modal => {
		modal.setAttribute('aria-hidden', 'false');
		if (backdrop) {
			modal.showModal();
		} else {
			modal.show();
		}
		modal.classList.add('show');
		global.setTimeout(() => {
			afterShow();
		}, 300);
	};

	let isClosed = false;
	const close = modal => {
		modal.setAttribute('aria-hidden', 'true');
		if (!isClosed) {
			isClosed = true;
			beforeClose();
			modal.classList.remove('show');
			global.setTimeout(() => {
				modal.close();
				global.setTimeout(() => {
					afterClose();
				}, 20);
			}, 300);
		}
	};

	dialogPolyfill.registerDialog(modal); // polyfill for Firefox, IE and Safari

	beforeShow();

	global.setTimeout(() => {
		show(modal);

		if (closeBtn) {
			closeBtn.addEventListener('click', e => {
				e.preventDefault();
				close(modal);
			});

			if (backdrop) {
				modal.addEventListener('click', e => {
					if (e.target === modal) {
						close(modal);
					}
				});
			}
		}

		modal.addEventListener('keydown', (e = global.event) => {
			let isEscape = false;

			if ('key' in e) {
				isEscape = e.key === 'Escape' || e.key === 'Esc';
			} else {
				isEscape = e.keyCode === 27;
			}

			if (isEscape) {
				modal.classList.remove('show');
			}
		});
	}, 20);
};

export default modal;