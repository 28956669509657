const onScroll = elem => {
    const top = elem.getBoundingClientRect().top;
    elem.querySelector('.content-callout__img-back').style.transform = `translateY(${top * 0.05}px)`;
    elem.querySelector('.content-callout__img-front').style.transform = `translateY(${top * 0.2}px)`;
};

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const { target, isIntersecting } = entry;
        const handleScroll = () => { onScroll(target); };

        if (isIntersecting) {
            global.addEventListener('scroll', handleScroll, true);
        } else {
            global.removeEventListener('scroll', handleScroll, true);
        }
    });
});

Array.from(document.querySelectorAll('.content-callout')).forEach(elem => {
    observer.observe(elem);
});
