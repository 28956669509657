const checkIfHasValue = (itemClassList, itemFormControlValue) => {
    if (itemFormControlValue !== '') {
        itemClassList.add('has-value');
    } else {
        itemClassList.remove('has-value');
    }
};

Array.from(document.querySelectorAll('.mdc-text-field')).forEach(item => {
    const { classList } = item;
    const fc = item.querySelector('.form-control');

    checkIfHasValue(classList, fc.value);
    
    fc.addEventListener('focus', () => {
        classList.add('has-focus');
        classList.remove('has-error');
    });

    fc.addEventListener('blur', () => {
        classList.remove('has-focus');
    });

    fc.addEventListener('change', () => {
        checkIfHasValue(classList, fc.value);
    });
});