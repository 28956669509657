import './components/video-handler';
import './components/content-callout';
import './components/forms';
import './components/modals';
import './components/navbar';
import './components/related-content-slider';
import './components/scroll-reveal';
import './components/see-the-difference-slider';
import './components/where-butter-comes-from';
import './forms/contact';
import './forms/email-sign-up';
import './forms/review';
import './templates/collection';
import './templates/recipes';